import { ThemeOptions } from '@mui/material/styles';

export const DarkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#e8c0ff',
    },
    secondary: {
      main: '#1EABE0',
    },
    background: {
      paper: "#424242",
      default: "#303030"
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          height: "28px"
        },
        label: {
          padding: "0 6px"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "30px"
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 15px'
        }
      }
    }
  }
};
